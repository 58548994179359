import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import PhoneInput from "react-phone-input-2"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hooks-helper"
import useValidator from "./useValidator"

import "react-phone-input-2/lib/style.css"

let recaptchaInstanceCalc
let recaptchaInstanceFast

const initCalcState = {
  fio_c: "",
  email_c: "",
  phone_c: "",
  city: "",
  cargo_k: "",
  cargo_w: "",
  cargo_o: "",
  cargo_d: "",
  valueReCAPTCHA: "",
  respondClass: "",
  respondMess: "",
  loading: false,
}

const initFastState = {
  name: "",
  email: "",
  phone: "",
  content: "",
  valueReCAPTCHA: "",
  respondClass: "",
  respondMess: "",
  loading: false,
}

const Calc = ({ intl, country }) => {
  const [formData, setForm] = useForm(initCalcState)

  const [validator, showValidationMessage] = useValidator({
    default: intl.messages.error,
  })

  const forSetForm = (name, value) => {
    setForm({
      target: {
        name: name,
        value: value,
      },
    })
  }

  const SubmitForm = e => {
    const formValid = validator.allValid()
    forSetForm("respondClass", "")
    forSetForm("respondMess", "")

    e.preventDefault()

    if (formValid) {
      const postData = new FormData()
      postData.append("fio", formData["fio_c"])
      postData.append("email", formData["email_c"])
      postData.append("phone", formData["phone_c"])
      postData.append("city", formData["city"])
      postData.append("cargo_w", formData["cargo_w"])
      postData.append("cargo_o", formData["cargo_o"])
      postData.append("cargo_d", formData["cargo_d"])
      postData.append("cargo_k", formData["cargo_k"])
      postData.append("g-recaptcha-response", formData["valueReCAPTCHA"])
      forSetForm("loading", true)

      fetch("/calculate.php", {
        method: "POST",
        body: postData,
      })
        .then(response => response.json())
        .then(data => {
          forSetForm("loading", false)
          forSetForm("respondClass", data.type)
          forSetForm("respondMess", data.text)
          ;[
            "fio_c",
            "email_c",
            "phone_c",
            "city",
            "cargo_w",
            "cargo_o",
            "cargo_d",
            "cargo_k",
            "g-recaptcha-response",
          ].forEach(item => forSetForm(item, ""))
          recaptchaInstanceCalc.reset()
          setTimeout(() => {
            ;["respondClass", "respondMess"].forEach(item =>
              forSetForm(item, "")
            )
          }, 7500)
        })

      showValidationMessage(false)
    } else {
      showValidationMessage(true)
    }
  }

  return (
    <form className="fastform" onSubmit={SubmitForm}>
      <div className="fastform__row">
        <h2>
          <FormattedMessage id="calcTitle" />
        </h2>
      </div>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message("fio_c", formData.fio_c, "required")}
          <input
            type="text"
            name="fio_c"
            value={formData.fio_c}
            placeholder={intl.messages.calcName}
            autoComplete={"off"}
            onChange={setForm}
          />
        </div>
      </div>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message("email_c", formData.email_c, "required|email")}
          <input
            type="email"
            name="email_c"
            value={formData.email_c}
            placeholder={intl.messages.calcEmail}
            autoComplete={"off"}
            onChange={setForm}
          />
        </div>
        <div className="fastform__input">
          {validator.message("phone_c", formData.phone_c, "required")}
          <PhoneInput
            country={country}
            autocompleteSearch={true}
            value={formData.phone_c}
            onChange={phone => forSetForm("phone_c", phone)}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name
              } else if (value.match(/1234/)) {
                return false
              } else {
                return true
              }
            }}
            inputProps={{
              name: "phone_c",
              autoComplete: "off",
              placeholder: intl.messages.contactLabelPhone,
              required: true,
            }}
          />
        </div>
      </div>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message("city", formData.city, "required")}
          <input
            type="text"
            name="city"
            value={formData.city}
            placeholder={intl.messages.calcCity}
            autoComplete={"off"}
            onChange={setForm}
          />
        </div>
        <div className="fastform__input">
          {validator.message("cargo_k", formData.cargo_k, "required")}
          <input
            type="text"
            name="cargo_k"
            value={formData.cargo_k}
            placeholder={intl.messages.calcDelivery}
            autoComplete={"off"}
            onChange={setForm}
          />
        </div>
      </div>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message("cargo_w", formData.cargo_w, "required|numeric")}
          <input
            type="text"
            name="cargo_w"
            value={formData.cargo_w}
            placeholder={intl.messages.calcCargoW}
            autoComplete={"off"}
            onChange={setForm}
          />
        </div>
        <div className="fastform__input">
          {validator.message("cargo_o", formData.cargo_o, "required|numeric")}
          <input
            type="text"
            name="cargo_o"
            value={formData.cargo_o}
            placeholder={intl.messages.calcCargoV}
            autoComplete={"off"}
            onChange={setForm}
          />
        </div>
      </div>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message("cargo_d", formData.cargo_d, "required")}
          <textarea
            name="cargo_d"
            value={formData.cargo_d}
            placeholder={intl.messages.calcDescr}
            onChange={setForm}
          />
        </div>
      </div>
      {formData.respondMess !== "" ? (
        <div className="fastform__row fastform__row__alert">
          <span className={formData.respondClass}>{formData.respondMess}</span>
        </div>
      ) : (
        ""
      )}

      <div className="fastform__row fastform__row__submit">
        <div className="fastform__input">
          {validator.message(
            "valueReCAPTCHA",
            formData.valueReCAPTCHA,
            "required"
          )}
          <ReCAPTCHA
            ref={e => (recaptchaInstanceCalc = e)}
            hl={intl.language}
            sitekey={process.env.RECAPTCHA}
            onChange={value => forSetForm("valueReCAPTCHA", value)}
          />
        </div>
        <button
          className="fastform__submit btn btn--red"
          disabled={formData.loading}
        >
          <span>{intl.messages.calcBtnSend}</span>
        </button>
      </div>
    </form>
  )
}

const Fast = ({ intl, country }) => {
  const [formData, setForm] = useForm(initFastState)

  const [validator, showValidationMessage] = useValidator({
    default: intl.messages.error,
  })

  const forSetForm = (name, value) => {
    setForm({
      target: {
        name: name,
        value: value,
      },
    })
  }

  const SubmitForm = e => {
    const formValid = validator.allValid()
    forSetForm("respondClass", "")
    forSetForm("respondMess", "")

    e.preventDefault()

    if (formValid) {
      const postData = new FormData()
      postData.append("name", formData["name"])
      postData.append("email", formData["email"])
      postData.append("phone", formData["phone"])
      postData.append("content", formData["content"])
      postData.append("g-recaptcha-response", formData["valueReCAPTCHA"])
      forSetForm("loading", true)

      fetch("/contact.php", {
        method: "POST",
        body: postData,
      })
        .then(response => response.json())
        .then(data => {
          forSetForm("loading", false)
          forSetForm("respondClass", data.type)
          forSetForm("respondMess", data.text)
          ;[
            "name",
            "email",
            "phone",
            "content",
            "g-recaptcha-response",
          ].forEach(item => forSetForm(item, ""))
          recaptchaInstanceFast.reset()

          setTimeout(() => {
            ;["respondClass", "respondMess"].forEach(item =>
              forSetForm(item, "")
            )
          }, 7500)
        })

      showValidationMessage(false)
    } else {
      showValidationMessage(true)
    }
  }

  return (
    <form className="fastform" onSubmit={SubmitForm}>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message("name", formData.name, "required")}
          <input
            type="text"
            name="name"
            value={formData.name}
            placeholder={intl.messages.contactLabelName}
            autoComplete={"off"}
            onChange={setForm}
          />
        </div>
      </div>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message("email", formData.email, "required|email")}
          <input
            type="email"
            name="email"
            value={formData.email}
            placeholder={intl.messages.contactLabelEmail}
            autoComplete={"off"}
            onChange={setForm}
          />
        </div>
      </div>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message("phone", formData.phone, "required")}
          <PhoneInput
            country={country}
            value={formData.phone}
            onChange={phone => forSetForm("phone", phone)}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name
              } else if (value.match(/1234/)) {
                return false
              } else {
                return true
              }
            }}
            inputProps={{
              name: "phone",
              autoComplete: "off",
              placeholder: intl.messages.contactLabelPhone,
              required: true,
            }}
          />
        </div>
      </div>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message("content", formData.content, "required")}
          <textarea
            name="content"
            value={formData.content}
            placeholder={intl.messages.contactLabelMess}
            onChange={setForm}
          />
        </div>
      </div>
      <div className="fastform__row">
        <div className="fastform__input">
          {validator.message(
            "valueReCAPTCHA",
            formData.valueReCAPTCHA,
            "required"
          )}
          <ReCAPTCHA
            ref={e => (recaptchaInstanceFast = e)}
            sitekey={process.env.RECAPTCHA}
            hl={intl.language}
            onChange={value => forSetForm("valueReCAPTCHA", value)}
          />
        </div>
      </div>
      {formData.respondMess !== "" ? (
        <div className="fastform__row fastform__row__alert">
          <span className={formData.respondClass}>{formData.respondMess}</span>
        </div>
      ) : (
        ""
      )}

      <div className="fastform__row fastform__row__submit">
        <button
          className="fastform__submit btn btn--red"
          disabled={formData.loading}
        >
          <span>{intl.messages.contactBtnSend}</span>
        </button>
      </div>
    </form>
  )
}

export { Calc, Fast }
