import { useRef, useState, useEffect } from "react"

const useOnScreen = ref => {
  const observerRef = useRef(null)
  const [isOnScreen, setIsOnScreen] = useState(false)

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    )
  }, [])

  useEffect(() => {
    observerRef.current.observe(ref.current)

    return () => {
      observerRef.current.disconnect()
    }
  }, [ref])

  return isOnScreen
}

const observeElement = ({
  element,
  marginValue = 400,
  thresholdValue = 0,
  callback = () => {},
}) => {
  if (element && typeof element === "string") {
    const targets = document.querySelectorAll(element)
    const targetsConfig = {
      rootMargin: `${marginValue}px 0px ${marginValue}px 0px`,
      threshold: thresholdValue,
    }

    const elementObserver = new IntersectionObserver((entries, self) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          self.unobserve(entry.target)
          callback(entry.target)
        }
      })
    }, targetsConfig)

    if (targets) {
      ;[].slice.call(targets).forEach(target => {
        elementObserver.observe(target)
      })
    }
  }
}

export { useOnScreen, observeElement }
