import React, { useRef, useState, useEffect } from "react"

import { FormattedMessage } from "gatsby-plugin-intl"

import { CountUp } from "use-count-up"

import classNames from "classnames"

import Rellax from "rellax"

import { LazyLoadComponent } from "react-lazy-load-image-component"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Modal from "../components/modal"
import GoogleMapComponent from "../components/googleMapComponent"
import { Calc, Fast } from "../components/form"
import { useOnScreen, observeElement } from "../components/utils"

const IndexPage = props => {
  const [state, setState] = useState({
    auth: false,
    activeFeature: null,
    openedAccardion: 1,
    country: "",
  })
  const [accordionCounter, setAccordionCounter] = useState(0)
  const [show, setShow] = useState(false)

  const intl = props.pageContext.intl

  const counterRefs = useRef([])
  counterRefs.current = [...Array(4).keys()].map(
    index => (counterRefs.current[index] = React.createRef())
  )

  let sectionMap = React.createRef()

  useEffect(() => {
    const accardionCounterEvents = () => {
      const accardionRefs = document.querySelectorAll(
        ".services__accardion__item"
      )

      accardionRefs.forEach((ref, index) => {
        const t = ref.getBoundingClientRect()
        const elemTop = t.top
        const elemBottom = t.top + t.height + 300
        if (elemTop >= 0 && elemBottom <= window.innerHeight)
          setAccordionCounter(index)
      })
    }

    ;["scroll", "resize"].forEach(e => {
      window.addEventListener(e, accardionCounterEvents)
    })

    return () =>
      ["scroll", "resize"].forEach(e => {
        window.removeEventListener(e, accardionCounterEvents)
      })
  }, [accordionCounter])

  useEffect(() => {
    const rellax = new Rellax(".rellax", {
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
      breakpoints: [576, 768, 1201],
    })

    return () => rellax.destroy()
  }, [])

  useEffect(() => {
    observeElement({
      element: ".animate",
      marginValue: 0,
      callback: bgImg => {
        bgImg.classList.add("show")
      },
    })

    observeElement({
      element: "[data-observed-bg]",
      marginValue: 300,
      callback: bgImg => {
        bgImg.classList.add("show")
      },
    })

    fetch("/infodesk/auth")
      .then(response => response.json())
      .then(({ auth }) => {
        setState({
          ...state,
          country: auth.country.toLowerCase(),
          auth: auth.login,
        })
      })
      .catch(() => {
        setState({
          ...state,
          country: intl.language,
          auth: false,
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const modalToggle = () => {
    document.body.classList.toggle("OverflowAll")
    document.documentElement.classList.toggle("OverflowAll")
    ;[...Array(3).keys()].map(i =>
      document.querySelector(`#${show ? `close` : `open`}-${i}`).beginElement()
    )
    setShow(!show)
  }

  return (
    <>
      <Layout props={props}>
        <Seo
          title={intl.messages.title}
          description={intl.messages.description}
          keywords={intl.messages.keywords}
        />

        <section className="section section-hero">
          <div className="section-hero__content">
            <h1 className="section__title section__title-white">
              <span>
                <FormattedMessage id="heroTitleSpan" />
              </span>{" "}
              <FormattedMessage id="heroTitle" />
            </h1>
            <ul className="list--clean list--dote-white">
              <li>
                <FormattedMessage id="heroListItem1" />
              </li>
              <li>
                <FormattedMessage id="heroListItem2" />
              </li>
              <li>
                <FormattedMessage id="heroListItem3" />
              </li>
            </ul>
            <p className="section__subtitle">
              <FormattedMessage id="heroSubTitle" />
            </p>
            <div className="section-hero__btns">
              <button onClick={() => modalToggle()} className="btn btn--red">
                <span className="small">
                  <FormattedMessage id="heroButton1" />
                </span>
              </button>
              <a
                href={`/files/${intl.language}/pricelist.pdf`}
                className="btn btn--red"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className={intl.language === "ru" ? "small" : ""}>
                  <FormattedMessage id="heroButton2" />
                </span>
              </a>
            </div>
          </div>

          <div className="section-hero__features">
            <ul className="list--clean features__list">
              {[...Array(4).keys()].map(i => (
                <li
                  key={i}
                  role="presentation"
                  className={`features__item features__item-${i}`}
                  onMouseEnter={() => setState({ ...state, activeFeature: i })}
                  onMouseLeave={() =>
                    setState({ ...state, activeFeature: null })
                  }
                >
                  <div className="features__item__name">
                    <span>
                      <FormattedMessage id={`featureTitleSpan${i + 1}`} />
                    </span>{" "}
                    <FormattedMessage id={`featureTitle${i + 1}`} />
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="features__contents">
            {[...Array(4).keys()].map(i => (
              <div
                key={i}
                className={classNames(`features__content`, {
                  active: state.activeFeature === i,
                })}
              >
                <div className="features__content__text">
                  <h2 className="section__title section__title-white">
                    <div>
                      <span>
                        <FormattedMessage id={`featureTitleSpan${i + 1}`} />
                      </span>{" "}
                      <FormattedMessage id={`featureTitle${i + 1}`} />
                    </div>
                  </h2>
                  <p className="section__subtitle">
                    <FormattedMessage id={`featureSubTitle${i + 1}`} />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="section section-about animate">
          <div className="section-about__content">
            <h2
              className="section__title section__title-red rellax"
              data-rellax-xs-speed="0"
              data-rellax-mobile-speed="0"
              data-rellax-tablet-speed="0"
              data-rellax-speed="-1.5"
            >
              <FormattedMessage id="aboutUsTitle" />
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: intl.messages.aboutUsText }}
            />
          </div>
        </section>

        <section className="section section-benefits animate">
          <div className="benefits__list">
            <div
              className="benefits__item benefits__item-1"
              data-observed-bg
            ></div>
            <div
              className="benefits__item benefits__item-2 rellax"
              data-rellax-xs-speed="0"
              data-rellax-mobile-speed="0"
              data-rellax-tablet-speed="0"
              data-rellax-speed="-1.5"
            >
              <div className="benefit benefit-red">
                <div className="benefit__number" ref={counterRefs.current[0]}>
                  <CountUp
                    isCounting={useOnScreen(counterRefs.current[0])}
                    end={6}
                    duration={1.2}
                  />
                  +
                </div>
                <div className="benefit__text">
                  <FormattedMessage id="benefitText1" />
                </div>
              </div>
              <div className="benefit benefit-white">
                <div className="benefit__number" ref={counterRefs.current[1]}>
                  <CountUp
                    isCounting={useOnScreen(counterRefs.current[1])}
                    end={30385}
                    duration={5.2}
                  />
                  +
                </div>
                <div className="benefit__text">
                  <FormattedMessage id="benefitText2" />
                </div>
              </div>
            </div>
            <div
              className="benefits__item benefits__item-3 rellax"
              data-rellax-xs-speed="0"
              data-rellax-mobile-speed="0"
              data-rellax-tablet-speed="0"
              data-rellax-speed="-1"
            >
              <div className="benefit benefit-gray">
                <div className="benefit__number" ref={counterRefs.current[2]}>
                  <CountUp
                    isCounting={useOnScreen(counterRefs.current[2])}
                    end={1899}
                    duration={3.2}
                  />
                  +
                </div>
                <div className="benefit__text">
                  <FormattedMessage id="benefitText3" />
                </div>
              </div>
              <div className="benefit benefit-red">
                <div className="benefit__number" ref={counterRefs.current[3]}>
                  <CountUp
                    isCounting={useOnScreen(counterRefs.current[3])}
                    end={2500}
                    duration={3.2}
                  />
                  +
                </div>
                <div className="benefit__text">
                  <FormattedMessage id="benefitText4" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section section-services" data-observed-bg>
          <div className="section-services__content animate">
            <h2 className="section__title section__title-white">
              <FormattedMessage id="servicesTitle" />
            </h2>

            <div className="services">
              <div className="services__counter__container">
                <div className="services__counter">
                  <span>
                    <FormattedMessage id="servicesSubTitle" />
                  </span>
                  <ol
                    className="services__counter__list"
                    style={{
                      transform: `translateY(-${300 * accordionCounter}px)`,
                    }}
                  >
                    {[...Array(4).keys()].map(i => (
                      <li key={i}>{i + 1}</li>
                    ))}
                  </ol>
                </div>
              </div>
              <div className="services__accardion">
                {[...Array(4).keys()].map(i => (
                  <div
                    key={i}
                    className={classNames("services__accardion__item", {
                      open: state.openedAccardion === i + 1,
                    })}
                    role="button"
                    tabIndex="0"
                    onClick={() =>
                      setState({
                        ...state,
                        openedAccardion:
                          state.openedAccardion !== i + 1 ? i + 1 : null,
                      })
                    }
                    onKeyDown={() =>
                      setState({
                        ...state,
                        openedAccardion:
                          state.openedAccardion !== i + 1 ? i + 1 : null,
                      })
                    }
                  >
                    <div className="services__accardion__header">
                      <FormattedMessage id={`servicesItemTitle${i + 1}`} />
                    </div>
                    <div
                      className={classNames("services__accardion__body", {
                        open: state.openedAccardion === i + 1,
                      })}
                      dangerouslySetInnerHTML={{
                        __html: intl.messages[`servicesItemText${i + 1}`],
                      }}
                    />
                    <div
                      className={classNames("services__accardion__toggle", {
                        open: state.openedAccardion === i + 1,
                      })}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="section section-registr">
          <div className="section-registr__content animate">
            <div>
              <h2 className="section__title section__title-white">
                <FormattedMessage id="registrBlockTitle" />
              </h2>
              <div className="section__subtitle">
                <FormattedMessage id="registrBlockSubTitle" />
              </div>
            </div>

            <div className="section-registr__btn">
              <a
                href={
                  state.auth
                    ? "/PersonalAccount/"
                    : "/PersonalAccount/public/sendRequest"
                }
              >
                <FormattedMessage
                  id={state.auth ? "headerCabinetUrlName" : "registrBlockBtn"}
                />
                <i data-observed-bg />
              </a>
            </div>
          </div>
        </section>

        <LazyLoadComponent
          threshold="0"
          afterLoad={() => sectionMap.classList.add("show")}
        >
          <section
            className="section section-map animate"
            ref={el => (sectionMap = el)}
          >
            <div className="section-map__form">
              <div className="section-map__form__content">
                <h2 className="section__title section__title-red">
                  <FormattedMessage id="contactTitle" />
                </h2>
                <Fast intl={intl} country={state.country} />
              </div>
            </div>
            <div className="section-map__map">
              <GoogleMapComponent
                key={`map-${intl.language}`}
                language={intl.language}
              />
            </div>
          </section>
        </LazyLoadComponent>
      </Layout>
      <Modal onClose={() => modalToggle()} show={show}>
        <Calc intl={intl} country={state.country} />
      </Modal>
    </>
  )
}

export default IndexPage
