import React, { useEffect } from "react"
import classNames from "classnames"

const Modal = props => {
  useEffect(() => {
    const closeOnEscapeKeyDown = e =>
      (e.charCode || e.keyCode) === 27 && props.show && props.onClose()
    document.body.addEventListener("keydown", closeOnEscapeKeyDown)
    return () =>
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown)
  }, [props])

  return (
    <div
      className={classNames("modal", {
        show: props.show,
      })}
      role="button"
      tabIndex="0"
      onClick={props.onClose}
      onKeyDown={props.onClose}
    >
      <svg
        className="modal-svg"
        height="100%"
        width="100%"
        preserveAspectRatio="none"
        viewBox="0 0 800 500"
      >
        <path d="M-59.9,540.5l-0.9-1.4c-0.1-0.1,0-0.3,0.1-0.3L864.8-41c0.1-0.1,0.3,0,0.3,0.1l0.9,1.4c0.1,0.1,0,0.3-0.1,0.3L-59.5,540.6 C-59.6,540.7-59.8,540.7-59.9,540.5z">
          <animate
            id="open-0"
            begin="indefinite"
            fill="freeze"
            attributeName="d"
            dur="500ms"
            to={
              "M33.8,690l-188.2-300.3c-0.1-0.1,0-0.3,0.1-0.3l925.4-579.8c0.1-0.1,0.3,0,0.3,0.1L959.6,110c0.1,0.1,0,0.3-0.1,0.3 L34.1,690.1C34,690.2,33.9,690.1,33.8,690z"
            }
          />
          <animate
            id="close-0"
            begin="indefinite"
            fill="freeze"
            attributeName="d"
            dur="500ms"
            to={
              "M-59.9,540.5l-0.9-1.4c-0.1-0.1,0-0.3,0.1-0.3L864.8-41c0.1-0.1,0.3,0,0.3,0.1l0.9,1.4c0.1,0.1,0,0.3-0.1,0.3L-59.5,540.6 C-59.6,540.7-59.8,540.7-59.9,540.5z"
            }
          />
        </path>
        <path d="M-465.1,287.5l-0.9-1.4c-0.1-0.1,0-0.3,0.1-0.3L459.5-294c0.1-0.1,0.3,0,0.3,0.1l0.9,1.4c0.1,0.1,0,0.3-0.1,0.3 l-925.4,579.8C-464.9,287.7-465,287.7-465.1,287.5z">
          <animate
            id="open-1"
            begin="indefinite"
            fill="freeze"
            attributeName="d"
            dur="500ms"
            to={
              "M-329.3,504.3l-272.5-435c-0.1-0.1,0-0.3,0.1-0.3l925.4-579.8c0.1-0.1,0.3,0,0.3,0.1l272.5,435c0.1,0.1,0,0.3-0.1,0.3 l-925.4,579.8C-329,504.5-329.2,504.5-329.3,504.3z"
            }
          />
          <animate
            id="close-1"
            begin="indefinite"
            fill="freeze"
            attributeName="d"
            dur="500ms"
            to={
              "M-465.1,287.5l-0.9-1.4c-0.1-0.1,0-0.3,0.1-0.3L459.5-294c0.1-0.1,0.3,0,0.3,0.1l0.9,1.4c0.1,0.1,0,0.3-0.1,0.3 l-925.4,579.8C-464.9,287.7-465,287.7-465.1,287.5z"
            }
          />
        </path>
        <path d="M341.1,797.5l-0.9-1.4c-0.1-0.1,0-0.3,0.1-0.3L1265.8,216c0.1-0.1,0.3,0,0.3,0.1l0.9,1.4c0.1,0.1,0,0.3-0.1,0.3L341.5,797.6 C341.4,797.7,341.2,797.7,341.1,797.5z">
          <animate
            id="open-2"
            begin="indefinite"
            fill="freeze"
            attributeName="d"
            dur="500ms"
            to={
              "M476.4,1013.4L205,580.3c-0.1-0.1,0-0.3,0.1-0.3L1130.5,0.2c0.1-0.1,0.3,0,0.3,0.1l271.4,433.1c0.1,0.1,0,0.3-0.1,0.3 l-925.4,579.8C476.6,1013.6,476.5,1013.5,476.4,1013.4z"
            }
          />
          <animate
            id="close-2"
            begin="indefinite"
            fill="freeze"
            attributeName="d"
            dur="500ms"
            to={
              "M341.1,797.5l-0.9-1.4c-0.1-0.1,0-0.3,0.1-0.3L1265.8,216c0.1-0.1,0.3,0,0.3,0.1l0.9,1.4c0.1,0.1,0,0.3-0.1,0.3L341.5,797.6 C341.4,797.7,341.2,797.7,341.1,797.5z"
            }
          />
        </path>
      </svg>
      <div
        role="button"
        tabIndex="0"
        className="modal-content"
        onClick={e => e.stopPropagation()}
        onKeyDown={e => e.stopPropagation()}
      >
        <div className="modal-body">{props.children}</div>
        <button onClick={props.onClose} className="modal-close">
          ✕
        </button>
      </div>
    </div>
  )
}

export default Modal
